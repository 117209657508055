<template>
  <div>
    <v-btn
      :to="{ name: 'hallsCreateUpdate' }"
      class="btn btn-success btn-elevate kt-login__btn-primary text-white"
      >{{ $t("BUTTON.ADD_HALL") }}</v-btn
    >

    <v-container>
      <v-row no-gutters>
        <v-col cols="4">
          <v-text-field
            v-model="headers.search"
            prepend-inner-icon="search"
            clearable
            :label="$t('A.SEARCH')"
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <!-- <v-checkbox v-model="tableOptions.showSearch"
                      label="Търси по колона"
                      class="shrink"></v-checkbox>  -->
        </v-col>

        <v-col class="text-right" cols="2">
          <v-select
            v-model="headers.rows"
            :items="tableOptions.rowsPerPage"
            :label="$t('A.PER_PAGE')"
            chips
            attach
          ></v-select>
        </v-col>
      </v-row>

      <v-row class="t-header">
        <v-col
          v-for="column of headers.columns"
          :key="column.name"
          :cols="column.cols"
        >
          <span @click="changeOrder(column.name)">
            {{ $t("LABELS." + column.name) }}
            <span v-if="column.name == headers.order">{{
              orderIcon
            }}</span></span
          >
        </v-col>

        <v-col cols="2">
          Операции
        </v-col>
      </v-row>

      <div v-show="!isLoadingHalls">
        <v-row class="t-row" v-for="item of items" :key="item.id">
          <v-col
            class="t-col"
            v-for="col of headers.columns"
            :key="col.name"
            :cols="col.cols"
          >
            {{ item[col.name] }}
          </v-col>
          <v-col
            cols="2"
            class="t-col d-flex justify-content-start operation-container flex-wrap"
          >
            <EditButton :id="item.id" action-name="hallsCreateUpdate" />
            <LogLink :id="item.id" type="halls" class-name="ml-8 float-right" />
            <DeleteButton
              :id="item.id"
              :delete-action="deleteAction"
              :fetch-data="fetchData"
            />
            <!-- <a href="#" class="float-right" @click="deleteData(item)">Изтрий</a> -->
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-right">
            {{ totalItems }} {{ $t("A.RESULTS") }}
          </v-col>
        </v-row>
      </div>

      <v-progress-linear
        class="mt-5"
        color="#25bdad"
        buffer-value="0"
        stream
        reverse
        :active="isLoadingHalls"
      ></v-progress-linear>

      <Paginator
        :totalPages="totalPages"
        :page="headers.page"
        :maxVisibleButtons="tableOptions.maxVisibleButtons"
        @change-page="changePage"
      />
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";

import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { GET_HALLS, DELETE_HALL } from "@/store/halls.module";

import { mapGetters } from "vuex";

import Paginator from "@/components/Paginator";

import ListMixin from "@/common/mixins/List";
import LogLink from "@/components/Log/LogLink";
import EditButton from "@/components/ActionButtons/EditButton";
import DeleteButton from "@/components/ActionButtons/DeleteButton";

export default {
  name: "ListHalls",

  components: { LogLink, EditButton, DeleteButton, Paginator },
  mixins: [ListMixin],
  data() {
    return {
      action: "halls",
      deleteAction: DELETE_HALL,
      items: [],
      totalItems: 0,
      headers: {
        columns: [
          {
            name: "id",
            cols: "1"
          },
          {
            name: "name",
            search: ""
          },
          {
            name: "login.email"
          }
        ],
        search: "",
        rows: 10,
        page: 1,
        order: "id",
        orderType: "asc"
      }
    };
  },
  computed: {
    ...mapGetters(["isLoadingHalls"]),
    orderIcon: function() {
      let vm = this;

      return vm.headers.orderType == "asc" ? "▲" : "▼";
    }
  },
  watch: {
    "headers.search": _.debounce(function(new_value) {
      let vm = this;

      vm.headers.page = 1;
      if (new_value == null || new_value.length >= 3) {
        vm.fetchData();
      }
    }, 250),
    "headers.rows": _.debounce(function(new_value) {
      let vm = this;
      new_value = parseInt(new_value);
      window.localStorage.setItem(vm.action + "_rows", new_value);

      vm.headers.page = 1;
      vm.fetchData();
    }, 250),
    "headers.page": _.debounce(function() {
      let vm = this;
      vm.fetchData();
    }, 250)
  },
  mounted() {
    let vm = this;

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Зали" }]);
    vm.fetchData();
  },
  methods: {
    getOptions: function() {
      let vm = this;

      vm.headers.order =
        window.localStorage.getItem(vm.action + "_order") || "id";
      vm.headers.orderType =
        window.localStorage.getItem(vm.action + "_orderType") || "asc";
      vm.headers.rows = parseInt(
        window.localStorage.getItem(vm.action + "_rows") || 10
      );
    },
    changeOrder: _.debounce(function(key) {
      let vm = this;

      if (vm.headers.order == key) {
        let oType = vm.headers.orderType == "asc" ? "desc" : "asc";

        vm.headers.orderType = oType;
      } else {
        vm.headers.order = key;
      }

      vm.headers.page = 1;
      window.localStorage.setItem(vm.action + "_order", vm.headers.order);
      window.localStorage.setItem(
        vm.action + "_orderType",
        vm.headers.orderType
      );

      vm.fetchData();
    }, 250),
    fetchData: _.debounce(function() {
      let vm = this;

      vm.getOptions();

      vm.$store
        .dispatch(GET_HALLS, { payload: vm.headers })
        .then(data => {
          vm.$nextTick(function() {
            vm.items = data.data.data;
            vm.totalItems = data.data.recordsTotal;
            vm.totalPages = data.data.totalPages;
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "hallsList" });
          }
        });
    }, 500)
  },
  beforeDestroy: function() {
    this.items = null;
    delete this.items;
  }
};
</script>
